import * as firebaseApp from "firebase/app"
import * as firebaseMessaging from "firebase/messaging"

import getConfig from "next/config"
import { getProductDomain } from "./Product/ProductContext"
import { map } from "lodash/fp"
import { forEach, uniq } from "lodash"
// eslint-disable-next-line import/no-unresolved
import { EventState } from "app/features/events/EventUtils"


const { publicRuntimeConfig } = getConfig()

const VAPID_KEY =
  "BDarrNcJFSpzuVWse_WhOdj5l6IjzmwtMyBAP3M6o66tgZGQHpOebeRIzJ540yizY-FrSU57vyla6N4f9XzDl38"

export const FB_PN_TOKEN = "fb_pn_token"
export const EVENT_LIST = "event_notification_list"
export const EVENT_REMINDERS_ENABLED = "event_reminders_enabled"

let firebaseInitialized = false

// init firebase only one time to avoid fast refresh errors
const initFirebaseOnce = () => {
  if (!firebaseInitialized && !firebaseApp.getApps().length) {
    firebaseApp.initializeApp({
      apiKey: "AIzaSyBmAFpwMlbjGl3VzNh4dF97NalC9gcRel8",
      authDomain: "wellzesta-health-prd.firebaseapp.com",
      databaseURL: "https://wellzesta-health-prd.firebaseio.com",
      projectId: "wellzesta-health-prd",
      storageBucket: "wellzesta-health-prd.appspot.com",
      messagingSenderId: "466113275295",
      appId: "1:466113275295:web:af8ea9d2bfa954d6c209aa",
      measurementId: "G-NGPG9JWHZW",
    }, 'android')

    firebaseInitialized = true
  }
}

export const initPushNotifications = async (notificationFn) => {
  initFirebaseOnce()

  let firebaseAndroid = firebaseApp.getApp('android')
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
  }

  if (await firebaseMessaging.isSupported()) {
    const messaging = firebaseMessaging.getMessaging(firebaseAndroid)

    const currentToken = await firebaseMessaging.getToken(messaging,{
      vapidKey: VAPID_KEY,
    })

    if (currentToken) {
      window.localStorage.setItem(FB_PN_TOKEN, currentToken)
    }

    const messageEventListenerHandler = (event) => {
      notificationFn(event.data)
    }

    navigator.serviceWorker.removeEventListener(
      "message",
      messageEventListenerHandler
    )
    navigator.serviceWorker.addEventListener(
      "message",
      messageEventListenerHandler
    )
  }

  // else {
  //   throw new Error("firebase messaging not supported")
  // }
}


export const eventRemindersEnabled = () => {
    const reminder = window.localStorage.getItem(EVENT_REMINDERS_ENABLED)
    return reminder ? JSON.parse(reminder) : true;
}

function iOSPushSubscribe(topic, eventValue, unsubscribe) {
  console.log("iOSPushSubscribe " + topic)
  window.webkit.messageHandlers["push-subscribe"].postMessage(
    JSON.stringify({
      topic: topic, // topic name to subscribe/unsubscribe
      eventValue, // user object: name, email, id, etc.
      unsubscribe, // true/false
    })
  )
}

function reminderTopicFromEventTopic(eventTopic) {
  let eventId = eventTopic.split("-")[1];
  return `Events-Reminders-${eventId}`;
}

function topicForEvent(eventId, includeReminderTopic) {
  const topics = [`Event-${eventId}`]

  return includeReminderTopic
    ? topics.concat(`Events-Reminders-${eventId}`)
    : topics
}

// @ts-check
export const isEventStateSuitableForReminders = (eventState) => {
  switch (eventState) {
    case EventState.WAITLIST:
    case EventState.WAITING:
    case EventState.REGISTRATION_CLOSED:
    case EventState.COMING_SOON:
      return false;
    case EventState.REGISTERED:
    case EventState.Register:
      return true;
    default:
      throw new Error(`Unsupported state: ${eventState}`);
  }
}

export const subscribeToInitialTopics = async (userId, orgId, eventList) => {
  // const eventTopicList = await eventList?.map((idEvent) => `Event-${idEvent}`)
  const eventTopics =
    eventList
      ?.map((event) =>
        topicForEvent(
          event.id,
          eventRemindersEnabled() && isEventStateSuitableForReminders(event.state)
        )
      )
      ?.flat() ?? []
  const generalTopics = [
    `User-${userId}`,
    `Alerts-${orgId}`,
    `Organization-${orgId}`,
  ]
  const allTopics = [...generalTopics, ...eventTopics] // spread operator

  window.localStorage.setItem(EVENT_LIST, JSON.stringify(allTopics))
  await Promise.all(map((topic) => subscribeToTopic(topic), allTopics))
}

export const updateEventSubscribeTopics = async (eventList) => {
  const newEventTopics = eventList
    ?.map((event) =>
      topicForEvent(
        event.id,
        eventRemindersEnabled() && isEventStateSuitableForReminders(event.state)
      )
    )
    .flat()

  // eslint-disable-next-line
  var oldEventTopics = await JSON.parse(
    window.localStorage.getItem(EVENT_LIST) ?? "[]"
  )

  window.localStorage.removeItem(EVENT_LIST)

  newEventTopics.map((topic) => {
    const index = oldEventTopics?.indexOf(topic)

    if (index > -1) {
      const removeTopic = oldEventTopics?.splice(index, 1)[0]
      unsubscribeFromTopic(removeTopic)
      // window.localStorage.setItem(EVENT_LIST, JSON.stringify(oldEventTopics))
    } else {
      subscribeToTopic(topic)
      oldEventTopics?.push(topic)

      // window.localStorage.setItem(
      //   EVENT_LIST,
      //   JSON.stringify(oldEventTopics?.concat(topic))
      // )
    }
  })

  window.localStorage.setItem(EVENT_LIST, JSON.stringify(oldEventTopics ?? []))
}

export const subscribeToEventReminderTopics = async () => {
  console.log("subscribeToEventReminderTopics");

  // Retrieve the existing event topics from localStorage
  const oldEventTopics = await JSON.parse(
    window.localStorage.getItem(EVENT_LIST) ?? "[]"
  );

  const reminderTopics = map(
    (eventTopic) => reminderTopicFromEventTopic(eventTopic),
    oldEventTopics.filter((topic) => topic.includes("Event-") === true)
  )

  // Subscribe to each new reminder topic
  forEach(reminderTopics, async (topic) => {
      await subscribeToTopic(topic);
  });

  const updatedTopics = uniq(oldEventTopics + reminderTopics)

  // Update the localStorage with the new list of event topics
  window.localStorage.setItem(
    EVENT_LIST,
    JSON.stringify(updatedTopics ?? [])
  );
}


export const unsubscribeFromEventReminderTopics = async () => {
  console.log("unsubscribeFromEventReminderTopics")

  // eslint-disable-next-line
  const oldEventTopics = await JSON.parse(
    window.localStorage.getItem(EVENT_LIST) ?? "[]"
  )

  window.localStorage.removeItem(EVENT_LIST)

  forEach(oldEventTopics, async (topic) => {
    if(topic.includes('Reminders')) {
      await unsubscribeFromTopic(topic)
    }
  })

  const updatedTopics = oldEventTopics.filter((topic) => topic.includes('Reminders') === false)

  window.localStorage.setItem(
    EVENT_LIST,
    JSON.stringify(updatedTopics ?? [])
  )
}



export const unsubscribeFromTopics = async (
  user,
  organization,
  eventList
) => {
  const topics = [
    `User-${user.id}`,
    `Alerts-${organization.id}`,
    `Organization-${organization.id}`,
  ]
  let eventTopics = []

  if (eventList) {
    // eventTopics = await eventList?.map((idEvent) => `Event-${idEvent}`)
    eventTopics = eventList?.map((event) => topicForEvent(event.id, true))?.flat() ?? []
  } else {
    eventTopics = await JSON.parse(window.localStorage.getItem(EVENT_LIST))
  }

  const allTopics = [topics, eventTopics].flat()
  await Promise.all(map(unsubscribeFromTopic, allTopics))
}

export const subscribeToTopic = async (topic) => {
  console.log("Subscribe topic: " + topic)

  const productName = getProductDomain(window.location.hostname).productDomain

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
    iOSPushSubscribe(
      `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`,
      {},
      false
    )
  }

  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)
  if (!currentToken) {
    return
  }

  return requestSubscribeToTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const unsubscribeFromTopic = async (topic) => {
  console.log("Unsubscribe topic: " + topic)

  const productName = getProductDomain(window.location.hostname).productDomain

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
    iOSPushSubscribe(
      `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`,
      {},
      true
    )
  }

  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)

  if (!currentToken)
    throw new Error(
      "No push notification token found. Make sure to call initPushNotifications first."
    )

  return requestUnsubscribeFromTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const getFirebase = () => {
  initFirebaseOnce()
  return firebaseApp.getApp()
}

export const unregisterMessagingSW = () => {
  if (window.navigator.serviceWorker) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          if (
            registration.active.scriptURL.indexOf(
              "firebase-messaging-sw.js"
            ) !== -1
          ) {
            registration.unregister()
          }
        }
      })
  }
}

async function requestSubscribeToTopic(token, topic) {
  const response = await window.fetch("/api/subscribe_to_topic", {
    method: "POST",
    body: JSON.stringify({ token, topic }),
  })

  const data = await response.json()
  if (!data.success) {
    throw new Error(data.message)
  }

  return data
}

async function requestUnsubscribeFromTopic(token, topic) {
  const response = await window.fetch("/api/unsubscribe_from_topic", {
    method: "POST",
    body: JSON.stringify({ token, topic }),
  })

  const data = await response.json()
  if (!data.success) {
    throw new Error(data.message)
  }

  return data
}
