import React from "react"
import { useRouter } from "next/router"
import clsx from "clsx"
import { useOrganization } from "app/common/Organization"
import InternalLink from "app/common/InternalLink"
import OrganizationIcon from "app/common/icons/Organization"
import { Button, Text } from "app/wellzesta-ui"
import { useProduct } from "app/lib/Product/ProductContext"
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from "reakit/Disclosure"
import styles from "./SideNavbar.module.css"
import packageJson from "../../../package.json"
import { useImportSvg } from "app/lib/hooks/useImportSvg"
import { useFeatureFlags } from "app/lib/hooks/useFeatureFlags"
import { _P_BULLETIN_BOARD_ORGS, _P_FULL_COUNT_ORGS } from 'app/lib/FeatureFlags/Constants'

function SideNavbar() {
  const organization = useOrganization()
  const { routes } = useProduct()
  const router = useRouter()
  const { route } = router
  const { isFeatureFlagEnabledForOrg } = useFeatureFlags()
  const visibleDisclosureRef = React.useRef(null)

  const enableBulletinBoard = isFeatureFlagEnabledForOrg(_P_BULLETIN_BOARD_ORGS, organization?.id)
  const enableFullCount = isFeatureFlagEnabledForOrg(_P_FULL_COUNT_ORGS, organization?.id)
  return (
    <div
      data-test-id="sidebar"
      className="text-xl text-primary h-full flex flex-col"
    >
      <header className="flex-shrink-0 flex items-center p-4 h-19">
        <div className="pr-4 flex-shrink-0">
          <OrganizationIcon
            className="w-8 h-8 rounded-full"
            logoUrl={
              !organization?.logoUrl
                ? "/static/images/avafield.png"
                : organization?.logoUrl
            }
          />
        </div>
        <Text size="sm" className="font-bold text-black truncate">
          {organization?.shortName || organization?.name}
        </Text>
      </header>
      <nav aria-label="primary" role="navigation">
        {routes.map((item) => {
          if (item.items) {
            return (
              <NavSubmenuItem
                key={item.label}
                {...item}
                organizationSlug={organization?.slug}
                currentRoute={route}
                // setVisibleDisclosure={setVisibleDisclosure}
                visibleDisclosureRef={visibleDisclosureRef}
                enableBulletinBoard={enableBulletinBoard}
                enableFullCount={enableFullCount}
              />
            )
          }
          if (item.label === "Campus Life") {
            return <NavSegment key={item.label} {...item} />
          } else {
            return (
              <NavLink
                key={item.label}
                {...item}
                organizationSlug={organization?.slug}
                currentRoute={route}
              />
            )
          }
        })}
      </nav>
      <footer className="mt-auto">
        <span className="px-4 text-xs text-gray-400 font-semibold">
          V {packageJson.version}
        </span>
      </footer>
    </div>
  )
}

function NavSubmenuItem({
  label,
  items,
  organizationSlug,
  currentRoute,
  icon,
  visibleDisclosureRef,
  enableBulletinBoard = false,
  enableFullCount = false,
}) {
  const isChildActive = items.some((it) => it.href === currentRoute)
  const disclosure = useDisclosureState({
    visible: isChildActive,
    animated: true,
  })

  React.useEffect(() => {
    if (
      JSON.stringify(visibleDisclosureRef?.current) ===
      JSON.stringify(disclosure)
    )
      return
    if (disclosure.visible) {
      if (visibleDisclosureRef.current?.visible)
        visibleDisclosureRef?.current?.hide()
      visibleDisclosureRef.current = disclosure
    }
  }, [disclosure?.visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const IconElement = useImportSvg(icon)
  let filteredItems = items

  if (!enableBulletinBoard) {
    filteredItems = filteredItems.filter(x => x.label !== "Bulletin Board")
  }

  if (!enableFullCount) {
    filteredItems = filteredItems.filter(x => x.label !== "FullCount")
  }


  return (
    <div className="mx-2">
      <Disclosure
        as={Button}
        size="xs"
        theme="inverse"
        {...disclosure}
        className={`transition-colors duration-300 ease-in-out flex w-full items-center ${styles.navItem} text-gray-800 font-semibold rounded-none h-11 text-lg my-1`}
      >
        {icon && IconElement && <IconElement className={"mr-2"} />}
        {label}
        <ChevronIcon
          className={`${styles.navSubmenuIcon} ${
            disclosure.visible && styles.isExpanded
          } ml-auto`}
        />
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        className={clsx(
          isChildActive ? "bg-white" : "bg-transparent",
          styles.navSubmenu,
          "relative"
        )}
      >
        <div
          className="absolute top-0 w-px2 h-full bg-black"
          style={{ left: "1.625rem" }}
        />
        {filteredItems.map(({ label, href }) => {
          return (
            <NavLink
              key={label}
              label={label}
              href={href}
              organizationSlug={organizationSlug}
              currentRoute={currentRoute}
              isSubmenuItem
              target={label === "FullCount" ? "_blank" : ""}
            />
          )
        })}
      </DisclosureContent>
    </div>
  )
}

function NavLink({
  label,
  href,
  organizationSlug,
  currentRoute,
  isSubmenuItem,
  target,
  icon,
}) {
  const isActive =
    href === currentRoute ||
    (href.includes("/[organizationSlug]/users") &&
      currentRoute.includes("/[organizationSlug]/users"))
  const as = href
    ? href.replace("[organizationSlug]", organizationSlug)
    : "this_is_link"
  const theme = isActive
    ? styles.navActiveItem
    : isSubmenuItem
    ? styles.navSubItem
    : styles.navInactiveItem

  const subItemClass = isSubmenuItem
    ? `ml-10 ${!isActive && "font-normal"}`
    : ""

  const IconElement = useImportSvg(icon)

  return (
    <InternalLink
      href={href}
      as={as}
      size="xs"
      className={`transition-colors duration-300 ease-in-out h-16 ${theme} ${subItemClass} py-1 text-lg mx-2 max-h-11 my-1`}
      aria-label={label}
      {...(isActive && { "aria-current": "page" })}
      target={target}
      id="app-support"
    >
      {icon && IconElement && (
        <IconElement
          className={"mr-2"}
          stroke={isActive ? "var(--color-bg-primary-darkest)" : "currentColor"}
        />
      )}
      <span>{label}</span>
    </InternalLink>
  )
}

function NavSegment({ label }) {
  return (
    <div className={"h-16 py-4"}>
      <span
        style={{ color: "var(--color-bg-even-brighter)" }}
        className={"font-semibold px-4"}
      >
        {label}
      </span>
    </div>
  )
}

const ChevronIcon = ({ className }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 5L7.15833 6.175L10.975 10L7.15833 13.825L8.33333 15L13.3333 10L8.33333 5Z"
      fill="currentColor"
    />
  </svg>
)

export default React.memo(SideNavbar)
