import isMobile from "is-mobile"
import { useState, useCallback, useEffect } from "react"

const _navigator = typeof navigator === "object" ? navigator : {}
const ua = _navigator.userAgent || ""
const isTabletAgent = Boolean(
  ua.match(
    /iPad|Opera MiniTablet|Tablet|SM-T[0-9]+|KF[A-Z0-9]+|Nexus 9|Surface Pro|TB-[A-Z0-9]+|ASUS_Z[0-9]+D|A3-A[0-9]+/i
  )
)

export const BREAKPOINTS = {
  mobile: 0,
  mobileLg: 450,
  tablet: 768,
  tabletLg: 881,
  desktop: 1025,
}

const useBreakpoints = () => {
  const [screen, setScreen] = useState(undefined)

  const onResize = useCallback(() => {
    const current = getScreen()
    setScreen(current)
  }, [])

  const getScreen = () => {
    const width = isMobile({ featureDetect: true, tablet: true })
      ? window.outerWidth
      : window.innerWidth

    if (width < BREAKPOINTS.mobileLg) {
      return "mobile"
    } else if (width >= BREAKPOINTS.mobileLg && width < BREAKPOINTS.tablet) {
      return "mobile-lg"
    } else if (width >= BREAKPOINTS.tablet && width < BREAKPOINTS.tabletLg) {
      return "tablet"
    } else if (width >= BREAKPOINTS.tabletLg && width < BREAKPOINTS.desktop) {
      return "tablet-lg"
    } else {
      return "desktop"
    }
  }

  useEffect(() => {
    onResize()

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [onResize])

  return screen
}

export const useMobile = () => {
  const screen = useBreakpoints()

  return screen === "mobile" || screen === "mobile-lg"
}

export const useTablet = () => {
  const screen = useBreakpoints()

  return screen === "tablet-lg" || screen === "tablet" || isTabletAgent
}

export const useTabletLg = () => {
  const screen = useBreakpoints()

  return screen === "tablet-lg"
}

export const useDesktop = () => {
  const screen = useBreakpoints()

  return screen === "desktop"
}
