import { getAppVersion } from "app/utils"

export const EVENT_QUERY = "event_query"
export const VIMEO_JOB = "vimeo_job"
export const WZ_EMC_BEHAVIOR = "wz_emc_behavior"
export const WZ_FEEDBACK = "wz_feedback"
export const WZ_GCP_SERVICE_ACCOUNT = "wz_gcp_service_account"
export const WZ_OPENING_HOUR = "wz_opening_hour"
export const WZ_SESSION_TIME_FIX = "wz_session_time_fix"
export const WZ_VIDEO_POINTS = "wz_video_points"
export const _P_ENABLE_BULLETIN_BOARD = "_p_enable_bulletin_board"
export const _P_BULLETIN_BOARD_ORGS = "_p_bulletin_board_orgs"
export const _P_EVENTS_VENUE_FILTER_ORGS = "_p_events_venue_filter_orgs"
export const _P_FULL_COUNT_ORGS = "_p_full_count_orgs"
export const _O_NEW_DOCUMENT_SEARCH_ORGS = "_o_new_document_search_orgs"
export const _P_EVENT_REMINDERS_ENABLED = "_p_event_reminders_enabled"

export const L_P_RESIDENTS_TO_MEMBERS_ORGS = "l_p_residents_to_members_orgs"
export const L_O_AUTO_REFRESH_INTERVAL_SECONDS = "l_o_auto_refresh_interval_seconds"

export const L_O_LIFE_VERSION = "L_O_LIFE_VERSION"

// Elastic Search constants
export const L_P_HS_CALIBRATION_PANEL_ENABLED = "l_p_hs_calibration_panel_enabled"
export const L_O_ES_DOCUMENTS_SEARCH_MIN_SCORE = "l_o_es_documents_search_min_score"
export const L_O_ES_DOCUMENTS_KEYWORD_BOOST = "l_o_es_documents_keyword_boost"
export const L_O_ES_DOCUMENTS_SEMANTIC_BOOST = "l_o_es_documents_semantic_boost"

export const DEFAULT_FEATURE_FLAG_STATE = {
  wz_feedback: "true",
  l_o_auto_refresh_interval_seconds: 1200,
  L_O_LIFE_VERSION: getAppVersion().toString(),
  _p_bulletin_board_orgs: "",
  _p_events_venue_filter_orgs: "",
  _p_full_count_orgs: "",
  _o_new_document_search_orgs: "",
  _p_event_reminders_enabled: false,
  l_p_residents_to_members_orgs: "",
  l_p_hs_calibration_panel_enabled: false,
  l_o_es_documents_search_min_score: 5.0,
  l_o_es_documents_keyword_boost: 0.5,
  l_o_es_documents_semantic_boost: 0.5,
}
